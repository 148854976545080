import { ALL_THEMES } from 'lib/context/ThemeContext';
import { tv } from 'tailwind-variants';

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

export const tailwindVariants = tv({
  slots: {
    backgroundBG: ['bg-components-accordion-color-section-section-bg'],
    base: [
      'flex',
      'radius-themes-spacing-radius-large-general',
      'py-themes-general-surface-spacing-small-padding-y',
      'px-spacing-padding-large-2',
      'md:px-themes-general-surface-spacing-large-padding-x',
      'md:py-themes-general-surface-spacing-large-padding-y',
    ],
    componentDescription: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'text-components-accordion-color-body',
      'md:leading-bodySans-medium',
      'md:font-bodySans-medium',
      'md:text-bodySans-medium',
    ],
    componentHeaderContentWrapper: ['flex-1', 'mb-spacing-margin-small-6', 'md:mb-0'],
    componentTitle: [
      'font-header-small-small',
      'leading-header-small-small',
      'text-header-small-small',
      'text-components-accordion-color-title',
      'mb-components-accordion-spacing-small-section-title-margin-bottom',
      'md:font-header-large-small',
      'md:leading-leading-header-large-small',
      'md:text-header-large-small',
      'md:mb-themes-general-surface-spacing-large-title-margin-bottom',
    ],
    pane: [
      'hover:cursor-pointer',
      'bg-components-accordion-color-item-item-bg',
      'border-solid',
      'border-components-accordion-color-item-divider',
      'flex',
      'flex-col',
      'border-t-2',
      'border-b-2',
      'mb-components-accordion-spacing-small-item-item-space-between',
      'rounded-themes-radius-small-item',
      'px-components-accordion-spacing-small-item-padding-x',
      'last:mb-0',
      'md:mb-components-accordion-spacing-large-item-item-space-between',
      'md:px-components-accordion-spacing-large-item-padding-x',
      'md:rounded-components-accordion-spacing-large-item-item-radius',
    ],
    paneBody: [
      'paneBody',
      'hover:cursor-auto',
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'text-components-accordion-color-content-body',
      'py-components-accordion-spacing-large-content-padding-y',
      'md:font-bodySans-medium',
      'md:leading-bodySans-medium',
      'md:text-bodySans-medium',
    ],
    paneHeader: [
      'md:flex',
      'md:items-center',
      'md:gap-x-themes-general-surface-spacing-large-body-margin-right',
      'mb-components-accordion-spacing-small-copy-margin-bottom',
      'md:mb-components-accordion-spacing-large-content-title-margin-bottom',
    ],
    paneBodyWrapper: [],
    paneTitle: ['align-stretch', 'flex', 'flex-1', 'flex-col', 'justify-center'],
    paneTitleButton: [
      'flex',
      'items-center',
      'justify-between',
      'font-bodySans-large-semibold',
      'leading-bodySans-large-semibold',
      'text-bodySans-large-semibold',
      'text-components-accordion-color-item-title-item-default',
      'py-components-accordion-spacing-small-item-padding-y',
      'md:py-components-accordion-spacing-large-item-padding-y',
      'text-left',
      'w-full',
    ],
    paneTitleIcon: [
      'fill-components-accordion-color-item-icon-icon-default',
      '[&_svg]:h-[32px]',
      '[&_svg]:w-[32px]',
    ],
    paneTitleText: [
      'font-bodySans-large-semibold',
      'text-bodySans-large-semibold',
      'leading-bodySans-large-semibold',
      'text-components-accordion-color-item-title-item-default',
      'w-full',
    ],
    paneBodyMediaWrapper: [
      'flex',
      'md:py-components-accordion-spacing-large-content-content-space-between',
      'py-components-accordion-spacing-small-content-content-space-between',
      'w-full',
      'justify-center',
      '[&_img]:rounded-themes-radius-large-image',
    ],
    paneWrapper: ['flex', 'flex-col'],
    videoClasses: ['w-full', 'h-auto', 'aspect-video', 'max-w-[720px]'],
    wrapper: ['mx-auto', 'w-full', 'md:max-w-components-accordion-spacing-large-maxwidth'],
    disclaimerContentClasses: [
      'pt-components-accordion-spacing-small-content-content-space-between',
      'md:pt-components-accordion-spacing-large-content-content-space-between',
    ],
    disclaimerClasses: ['pt-spacing-margin-large-3'],
    themeBasedContainerClass: [],
    glossaryTabContainer: [],
    ingredientContainer: [],
    accordionTabContentHeader: [],
    ingredientTitle: [],
  },
  variants: {
    alignment: {
      left: {
        paneBody: [
          'text-left',
          'md:py-spacing-spacing-padding-large-5',
          'md:px-components-accordion-spacing-large-content-padding-x',
          'md:py-components-accordion-spacing-large-content-padding-y',
          'py-components-accordion-spacing-small-content-padding-y',
          'px-components-accordion-spacing-small-content-padding-x',
        ],
        paneTitle: ['flex-row'],
        paneTitleButton: ['flex-row', 'text-left'],
      },
      right: {
        paneBody: ['text-right'],
        paneTitle: ['flex-row-reverse'],
        paneTitleButton: ['flex-row-reverse', 'text-right'],
      },
    },
    isOpen: {
      false: {
        paneBodyWrapper: ['hidden'],
      },
      true: {
        paneBodyWrapper: ['block'],
      },
    },
    ingredientLength: {
      false: {
        wrapper: [],
      },
      true: {
        base: ['mt-components-accordion-spacing-large-content-padding-y'],
        wrapper: ['!mx-0', 'w-full', 'md:!max-w-full'],
      },
    },
    brand: {
      ...themeVariants,
      Corporate: {
        base: ['mx-0'],
        glossaryTabContainer: [
          'glossaryTabContainer',
          'hover:cursor-auto',
          'flex',
          'flex-col',
          'px-components-accordion-spacing-large-content-padding-x',
          'py-components-accordion-spacing-large-content-padding-y',
        ],
        accordionTabContentHeader: [
          'accordionTabContentHeader',
          'text-header-large-xSmall',
          'font-header-large-xSmall',
          'leading-header-large-xSmall',
          'pb-components-content-list-spacing-large-item-title-margin-bottom',
          'gap-components-accordion-spacing-large-content-content-space-between',
        ],
        ingredientContainer: [
          'md:columns-3',
          'columns-1',
          'gap-x-spacing-spacing-8',
          'list-disc',
          'list-outside',
          'ml-spacing-spacing-5',
          'pb-spacing-spacing-7',
        ],
        ingredientTitle: [
          'ingredientTitle',
          'break-all',
          'pb-spacing-spacing-3',
          'text-bodySans-medium',
          'font-bodySans-medium',
          'leading-bodySans-medium',
          'text-components-content-list-color-body',
        ],
        disclaimerClasses: ['pt-components-accordion-spacing-large-content-content-space-between'],
      },
    },
  },
});
