// Global
import { ALL_THEMES } from 'lib/context/ThemeContext';
import { tv } from 'tailwind-variants';

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

export const tailwindVariants = tv({
  slots: {
    // Here we are added product-information class for the splide slider.
    base: ['product-information-wrapper', 'bg-components-article-hero-color-bg', 'component'],
    totalWrapper: [
      'flex',
      'flex-col-reverse',
      'py-themes-general-surface-spacing-small-padding-y',
      'px-themes-general-surface-spacing-small-padding-x',
      'md:py-themes-general-surface-spacing-large-padding-y',
      'md:px-themes-general-surface-spacing-large-padding-x',
      'md:flex-row',
      'lg:items-center',
      'gap-components-article-hero-spacing-small-content-margin-top',
      'md:gap-spacing-spacing-8',
    ],
    heroArticleWrapper: [
      'component-content',
      'justify-center',
      'flex',
      'flex-col',
      'h-full',
      'flex-1',
    ],
    heroArticleTitle: [
      'article-title',
      'font-header-small-large',
      'leading-header-small-large',
      'text-header-small-large',
      'text-components-product-information-color-headline',
      'relative',
      'mb-components-article-hero-spacing-small-title-margin-bottom',
      'md:mb-components-article-hero-spacing-large-title-margin-bottom',
      'md:font-header-large-large',
      'md:leading-header-large-large',
      'md:text-header-large-large',
    ],
    authorWrapper: [
      'flex',
      'flex-wrap',
      'gap-components-article-hero-spacing-small-authorthumbnail-margin-left',
      'md:gap-components-article-hero-spacing-large-author-thumbnail-margin-left',
      'mb-components-article-hero-spacing-small-details-margin-bottom',
      'md:mb-components-article-hero-spacing-large-details-margin-bottom',
    ],
    authorNameDateWrapper: [
      'flex',
      'flex-col',
      'justify-center',
      'gap-components-article-hero-spacing-small-authorname-margin-bottom',
      'md:gap-components-article-hero-spacing-large-author-name-margin-bottom',
    ],
    heroAuthorName: [
      'article-author-name',
      'font-bodySans-large-semibold',
      'text-bodySans-large-semibold',
      'leading-bodySans-large-semibold',
      'md:font-bodySans-medium-semibold',
      'md:text-bodySans-medium-semibold',
      'md:leading-bodySans-medium-semibold',
      'text-components-article-hero-color-detail-text',
    ],
    heroAuthorDate: [
      'article-publish-date',
      'text-components-article-hero-color-detail-text',
      'text-bodySans-medium',
      'font-bodySans-medium',
      'leading-bodySans-medium',
    ],
    heroAuthorImage: ['rounded-radius-radius-8', 'object-cover', 'w-[64px]', 'h-[64px]'],
    heroArticleDescriptionWrapper: [
      'field-heroArticleDescription',
      'mb-components-article-hero-spacing-small-body-margin-bottom',
      'md:mb-components-article-hero-spacing-large-body-margin-bottom',
    ],
    heroArticleDescription: [
      'article-description',
      'relative',
      'font-bodySans-medium',
      'text-bodySans-medium',
      'leading-bodySans-medium',
      'md:font-bodySans-large',
      'md:text-bodySans-large',
      'md:leading-bodySans-large',
      'text-components-product-information-color-body',
    ],
    stateWrapper: [
      'flex',
      'flex-row',
      'mb-components-article-hero-spacing-small-stats-margin-bottom',
      'md:mb-components-article-hero-spacing-large-stats-margin-bottom',
    ],
    stateItem: ['flex'],
    stateInnerClass: ['flex', 'flex-col', 'gap-spacing-spacing-2'],
    stateCallOut: [
      'font-bodySans-small-semibold',
      'text-bodySans-small-semibold',
      'text-components-article-hero-color-detail-text',
      'leading-bodySans-small-semibold',
    ],
    stateLabel: [
      'font-header-large-xSmall',
      'text-header-large-xSmall',
      'text-components-stats-stat-text-on-light',
      'leading-header-large-xSmall',
    ],
    statDivider: [
      'min-h-[1em]',
      'w-0.5',
      'self-stretch',
      'bg-components-article-hero-color-divider',
      'mx-components-article-hero-spacing-small-space-between',
      'md:mx-components-article-hero-spacing-large-space-between',
    ],
    heroArticleCTAWrapper: ['field-heroArticletext', 'relative', '[&_a]:w-full', 'md:[&_a]:w-auto'],
    labelWrapper: [
      'flex',
      'flex-wrap',
      'mb-components-article-hero-spacing-small-label-share-margin-bottom',
      'md:mb-components-article-hero-spacing-large-label-share-margin-bottom',
      'gap-spacing-spacing-3',
    ],
    productInformationLeftWrapper: ['flex-1'],
    shareWrapper: ['ml-auto', 'order-2'],
  },
  variants: {
    alignment: {
      Center: {
        heroArticleWrapper: ['items-center'],
        heroArticleTitle: ['text-center'],
        heroArticleDescription: ['text-center'],
      },
      Left: {},
    },
    showPattern: {
      true: {
        heroArticleWrapper: [
          'bg-components-hero-internal-color-accent-bg',
          'md:bg-components-hero-internal-color-default-bg',
        ],
      },
      false: {
        heroArticleWrapper: ['bg-components-hero-internal-color-default-bg'],
      },
    },
    brand: {
      ...themeVariants,
      // For Corporate - At 150% device specification, we had to reduce the font size so added font size in "topTitle".
      Corporate: {
        heroArticleTitle: [
          'md:!font-header-large-medium',
          'md:!leading-header-large-medium',
          'md:!text-header-large-medium',
        ],
      },
    },
  },
});
