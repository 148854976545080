// Global
import { tv } from 'tailwind-variants';

export const tailwindVariants = tv({
  slots: {
    // Here we are added product-information class for the splide slider.
    base: [
      'product-information-wrapper',
      'md:py-spacing-spacing-7',
      'md:px-spacing-spacing-7',
      'py-spacing-spacing-6',
      'px-themes-general-surface-spacing-small-padding-x',
      'bg-components-product-information-color-bg',
      'overflow-hidden',
    ],
    containerClass: ['md:container', 'max-w-full'],
    totalWrapper: [
      'flex',
      'md:flex-row',
      'flex-col',
      'gap-components-product-information-spacing-small-carousel-space-between',
      'md:gap-components-product-information-spacing-large-carousel-space-between',
      'items-center',
    ],
    productPrimaryImage: ['aspect-[4/3]', 'basis-1/2'],
    heroWrapper: [
      'field-heroArticleDescription',
      'items-start',
      'basis-1/2',
      'flex',
      'flex-col',
      'h-full',
    ],
    heroTitle: [
      'article-title',
      'md:font-header-large-large',
      'md:text-header-large-large',
      'md:leading-header-large-large',
      'font-header-large-small',
      'text-header-large-small',
      'leading-header-large-small',
      'mb-components-product-information-spacing-small-title-margin-bottom',
      'md:mb-components-product-information-spacing-large-title-margin-bottom',
    ],
    heroButtons: [
      'flex',
      'flex-wrap',
      'w-full',
      'md:w-auto',
      'py-components-product-information-spacing-small-button-margin-y',
      'md:py-components-product-information-spacing-large-button-margin-y',
      'gap-6',
    ],
    heroDescription: [
      'article-description',
      'mt-components-product-information-spacing-small-body-margin-top',
      'md:mt-components-product-information-spacing-large-body-margin-top',
      'text-components-product-information-color-body',
      'font-bodySans-medium',
      'text-bodySans-medium',
      'leading-bodySans-medium',
    ],
  },
});
