// Global
import { tv } from 'tailwind-variants';

export const tailwindVariants = tv({
  defaultVariants: {
    cardOrientation: 0,
  },
  slots: {
    base: [
      'relative',
      'bg-components-generic-card-listing-color-bg',
      'py-components-generic-card-listing-spacing-small-padding-y', //token removed
      'px-themes-general-surface-spacing-small-padding-x',
      'lg:py-themes-general-surface-spacing-large-padding-x',
      'lg:px-themes-general-surface-spacing-large-padding-x',
    ],
    card: [
      'flex',
      'flex-col',
      'overflow-hidden',
      'rounded-themes-spacing-radius-large-card',
      'w-full',
      'border-components-generic-card-color-border-width',
      'border-components-article-card-color-border',
      'bg-components-generic-card-color-bg',
    ],
    cardBody: [
      'flex',
      'flex-col',
      'h-full',
      'py-components-generic-card-spacing-large-padding-content-y',
      'px-components-generic-card-spacing-large-padding-content-x',
    ],
    cardCtaWrapper: [
      'flex',
      'w-full',
      '[&>*]:flex-1',
      'gap-spacing-space-between-large-3',
      'flex-wrap',
    ],
    cardDescriptionText: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'line-clamp-4',
      'text-components-generic-card-color-copy',
      'mb-themes-general-card-spacing-large-body-margin-bottom',
      // these 2 classes resolve the issue and can be removed
      // when leading-trim gets implemented in browser spec
      // https://caniuse.com/?search=leading-trim
      'pt-[4px]',
      'border-b-4',
      'pb-[4px]',
      'border-transparent',
      'max-h-[84px]',
    ],
    cardHead: ['flex'],
    cardPrimaryCta: ['mr-spacing-space-between-large-2'],
    cardSubtitleText: [
      'text-bodySans-small-semibold',
      'font-bodySans-small-semibold',
      'leading-bodySans-small-semibold',
      'line-clamp-2',
      'text-components-generic-card-color-subtitle',
      'mb-themes-general-card-spacing-large-title-margin-bottom',
      // this class resolve the issue and can be removed
      // when leading-trim gets implemented in browser spec
      // https://caniuse.com/?search=leading-trim
      'after:!mt-0',
      'pt-[4px]',
      'border-b-4',
      'pb-[4px]',
      'border-transparent',
      'max-h-[50px]',
    ],
    cardTitleText: [
      'font-header-large-xSmall',
      'leading-header-large-xSmall',
      'text-header-large-xSmall',
      'mb-themes-general-card-spacing-large-title-margin-bottom',
      'text-components-generic-card-color-title',
    ],
    cardWrapper: [
      'grid',
      'grid-cols-1',
      'w-full',
      'sm:grid-cols-2',
      'gap-themes-general-surface-spacing-large-cards-space-between',
    ],
    componentBG: ['bg-components-generic-card-listing-color-bg'],
    ctaWrapper: [],
    descriptionText: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'md:font-bodySans-medium',
      'md:leading-bodySans-medium',
      'md:text-bodySans-medium',
      'text-components-generic-card-listing-color-copy',
    ],
    legalDisclaimerText: [
      'pt-spacing-spacing-5',
      'text-components-generic-card-listing-color-copy',
    ],
    svgDecorLeft: ['absolute', 'hidden', 'left-0', 'top-0', 'md:block'],
    svgDecorRight: ['absolute', 'hidden', 'right-0', 'top-0', 'md:block'],
    titleAndDescription: ['flex', 'flex-col', 'items-center', 'justify-center'],
    titleAndDescriptionWrapperInner: ['flex', 'flex-col', 'flex-1'],
    titleAndDescriptionWrapperOuter: [
      'flex',
      'flex-col',
      'md:flex-row',
      'justify-between',
      'w-full',
      'mb-themes-general-surface-spacing-small-margin-bottom',
      'md:mb-themes-general-surface-spacing-large-margin-bottom',
      'gap-themes-general-surface-spacing-small-body-margin-bottom',
      'md:gap-themes-general-surface-spacing-large-body-margin-right',
    ],
    titleText: [
      'font-header-small-large',
      'leading-header-small-large',
      'text-header-small-large',
      'md:font-header-large-large',
      'md:text-header-large-large',
      'md:leading-header-large-large',
      'mb-themes-general-surface-spacing-small-title-margin-bottom',
      'md:mb-themes-general-surface-spacing-large-title-margin-bottom',
      'text-components-generic-card-listing-color-title',
    ],
  },
  variants: {
    alignCTA: {
      BottomCenter: {
        ctaWrapper: [
          'align-center',
          'flex',
          'mt-themes-general-surface-spacing-small-button-margin-top',
          'md:mt-themes-general-surface-spacing-large-button-margin-top',
          'w-full',
          'sm:w-auto',
          '[&>a]:w-full',
          'sm:[&>a]:w-auto',
        ],
      },
      TopRight: {
        ctaWrapper: ['flex', 'items-center'],
      },
    },
    cardOrientation: {
      0: {
        cardWrapper: ['md:grid-cols-2'],
      },
      2: {
        cardWrapper: ['md:grid-cols-2'],
      },
      3: {
        cardWrapper: ['md:grid-cols-3'],
      },
      4: {
        cardWrapper: ['md:grid-cols-4'],
      },
    },
  },
});
