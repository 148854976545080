// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { Splide } from '@splidejs/react-splide';
import React from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { HeroComponents } from 'lib/templates/Feature.Dart.model';
import { ComponentProps } from 'lib/component-props';
import { ALL_THEMES, useTheme } from 'lib/context/ThemeContext';
import { ComponentVariants } from 'lib/context/ComponentVariants';
import fallback from 'lib/fallback/fallback';

// Local
import Carousel from 'components/authorable/Carousel/Carousel';
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import Label, { ColorTypes } from 'helpers/Label/Label';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import SVG from 'helpers/SVG/SVG';
import LegalDisclaimer from 'helpers/LegalDisclaimer/LegalDisclaimer';

export type Hero = HeroComponents.HomepageHero.HomepageHeroSlide;

export type HomepageHeroProps = ComponentProps & HeroComponents.HomepageHero.HomepageHeroSlideList;

const HomepageHeroDefaultComponent = (props: HomepageHeroProps): JSX.Element => (
  <div className={`component HomepageHero ${props?.params?.styles}`}>
    <div className="component-content">
      <span className="is-empty-hint">Homepage Hero</span>
    </div>
  </div>
);

export const HomepageHero = (props: HomepageHeroProps): JSX.Element => {
  const { heroSlideList, enablePattern, disclaimerText } = props?.fields || {};
  const { autoPlay, RenderingIdentifier, styles } = props?.params || {};
  const { componentName, dataSource } = props?.rendering || {};

  const { themeName } = useTheme();
  const componentVariants = ComponentVariants();

  // Add fallback component variant color
  const fallbackComponentVariantColor = fallback?.componentVariants?.value;

  const alignment = !!props.params.leftImage;
  const contentRef = React.createRef<Splide>();
  const id = RenderingIdentifier || undefined;
  const imageRef = React.createRef<Splide>();

  const themeVariants = ALL_THEMES.reduce(
    (acc, curr) => ((acc[curr] = {}), acc),
    {} as Record<string, object>
  );

  const tailwindVariants = tv({
    slots: {
      base: [
        styles,
        'bg-components-hero-homepage-color-bg',
        'component-content',
        'component',
        'flex-col',
        'flex',
        'overflow-hidden',
        'relative',
        'md:flex-row',
        'md:rounded-components-hero-homepage-spacing-large-container-radius',
      ],
      baseWrapper: [
        'p-0',
        'md:p-components-hero-homepage-spacing-large-container-padding',
        'bg-components-hero-homepage-color-container-bg',
      ],
      homeHeroLhsWrapper: [
        'lhs-wrapper',
        'flex-1',
        'item-center',
        'justify-center',
        'flex',
        'flex-col',
        'order-2',
        'md:max-w-[50%]',
      ],
      homeHeroRhsWrapper: ['rhs-wrapper', 'flex-1', 'order-1'],
      contentCarousel: [
        'flex-1',
        'flex-col',
        'flex',
        'max-lg:px-components-hero-internal-spacing-small-padding-x',
        'max-lg:py-components-hero-homepage-spacing-small-padding-y',
        'md:py-components-hero-homepage-spacing-large-padding-y',
        'order-1',
        'justify-center',
      ],
      contentCarouselTrack: [
        'p-components-hero-homepage-spacing-small-padding-x',
        'mb-components-hero-homepage-spacing-small-content-title-margin-bottom',
        'md:mb-components-hero-homepage-spacing-large-content-margin-bottom',
        '!overflow-visible',
      ],
      descriptionWrapper: [
        'font-bodySans-medium',
        'leading-bodySans-medium',
        'text-bodySans-medium',
        'md:font-bodySans-large',
        'md:leading-bodySans-large',
        'md:text-bodySans-large',
        'mb-components-hero-homepage-spacing-small-content-title-margin-bottom',
        'text-components-hero-homepage-color-body',
        'md:mb-themes-general-surface-spacing-large-body-margin-bottom',
      ],
      headlineText: [
        'font-header-small-large',
        'leading-header-small-large',
        'text-header-small-large',
        'text-components-hero-homepage-color-header',
        'md:font-header-large-large',
        'md:leading-header-large-large',
        'md:text-header-large-large',
      ],
      headlineTextWrapper: [
        'max-md:pb-themes-general-card-spacing-small-eyebrow-margin-bottom',
        'mt-components-hero-homepage-spacing-small-content-title-margin-bottom',
        'md:mt-0',
        'md:mb-themes-general-surface-spacing-large-title-margin-bottom',
      ],
      heroSlideSvgLarge: [
        'absolute',
        'h-full',
        'hidden',
        'md:block',
        'z-10',
        'top-0',
        '[&>*]:h-full',
        '[&>*]:w-auto',
      ],
      heroSlideSvgSmall: ['absolute', '-bottom-[1px]', 'w-full', 'md:hidden'],
      heroSlideWrapper: [
        'aspect-picture',
        'md:aspect-none',
        'max-w-full',
        'bg-cover',
        'bg-top',
        'h-full',
        'relative',
        'md:bg-center',
      ],
      heroImage: ['!object-cover'],
      imageCarousel: ['flex-1', 'order-1', 'h-full'],
      imageCarouselTrack: [
        'h-full',
        'md:rounded-components-hero-homepage-spacing-large-container-radius',
        '[&>ul>li]:w-full',
        '[&>ul>li]:h-full',
      ],
      label: [
        '!p-[8px]', // we have to update token after design change
        'md:mb-components-hero-homepage-spacing-large-content-margin-bottom',
        'mb-themes-general-card-spacing-small-eyebrow-margin-bottom',
      ],
      labelBg: ['bg-components-hero-homepage-color-bg'],
      primaryCtaLinkWrapper: ['HomepageHero-primaryCTA'],
      primaryCtaWrapper: [
        'field-HomepageHeroCTA',
        'flex-col',
        'flex',
        'gap-themes-general-card-spacing-small-button-space-between',
        'md:gap-themes-general-card-spacing-large-button-space-between',
        'md:flex-row',
      ],
      playPauseFill: ['fill-components-hero-homepage-color-play-pause'],
      secondaryCtaLinkWrapper: ['HomepageHero-secondaryCTA'],
      subTitleWrapper: [
        'font-header-large-xSmall',
        'leading-header-large-xSmall',
        'text-header-large-xSmall',
        'mb-components-hero-homepage-spacing-small-content-subtitle-margin-bottom',
        'text-components-hero-homepage-color-sub-header',
        'md:mb-components-hero-homepage-spacing-large-content-subtitle-margin-bottom',
      ],
      legalDisclaimerText: [
        'order-1',
        '!text-components-hero-homepage-color-body',
        'md:px-components-hero-homepage-spacing-large-padding-x',
      ],
    },
    variants: {
      alignment: {
        true: {
          contentCarousel: ['md:order-2'],
          heroSlideSvgLarge: ['right-[49.5%]', '-scale-x-100'],
          imageCarousel: ['md:order-1'],
          homeHeroLhsWrapper: ['md:order-2'],
          homeHeroRhsWrapper: ['md:order-1'],
        },
        false: {
          contentCarousel: ['md:order-1'],
          heroSlideSvgLarge: ['!left-[49.5%]'],
          imageCarousel: ['md:order-2'],
          homeHeroLhsWrapper: ['md:order-1'],
          homeHeroRhsWrapper: ['md:order-2'],
        },
      },
      brand: {
        ...themeVariants,
        Baygon: {
          labelBg: [
            'bg-components-label-color-on-white-background-bg-default',
            'text-components-label-color-on-white-label-label-default',
          ],
        },
      },
    },
  });

  const {
    base,
    baseWrapper,
    contentCarousel,
    contentCarouselTrack,
    descriptionWrapper,
    headlineText,
    headlineTextWrapper,
    heroSlideSvgLarge,
    heroSlideSvgSmall,
    heroSlideWrapper,
    heroImage,
    imageCarousel,
    imageCarouselTrack,
    label,
    labelBg,
    primaryCtaLinkWrapper,
    primaryCtaWrapper,
    playPauseFill,
    secondaryCtaLinkWrapper,
    subTitleWrapper,
    legalDisclaimerText,
    homeHeroLhsWrapper,
    homeHeroRhsWrapper,
  } = tailwindVariants({
    alignment: alignment ? true : false,
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });

  React.useEffect(() => {
    if (
      contentRef.current &&
      contentRef.current.splide &&
      imageRef.current &&
      imageRef.current.splide
    ) {
      contentRef.current.splide.sync(imageRef.current.splide);
    }
  }, [contentRef, imageRef]);

  const disclaimer = disclaimerText?.value ? (
    <LegalDisclaimer disclaimerText={disclaimerText} disclaimerClasses={legalDisclaimerText()} />
  ) : undefined;

  if (heroSlideList) {
    {
      return (
        <Container fullWidth>
          <div className={baseWrapper()}>
            <div className={base()} data-component="authorable/heroes/homepagehero" id={id}>
              {/* Content */}
              <div className={homeHeroLhsWrapper()}>
                <Carousel
                  disclaimer={disclaimer}
                  autoPlay={autoPlay !== 'Off' ? 'On' : autoPlay}
                  hasTrack={false}
                  gtmEvent={{
                    'gtm.element.dataset.gtmDatasourceId': dataSource,
                    'gtm.element.dataset.gtmComponentName': componentName,
                  }}
                  ref={contentRef}
                  carouselClasses={contentCarousel()}
                  trackClasses={contentCarouselTrack()}
                  carouselControls={{
                    buttons: {
                      prev: {
                        icon: 'chevron_left',
                      },
                      next: {
                        icon: 'chevron_right',
                      },
                      playPause: playPauseFill(),
                    },
                    navigationButtonVariant:
                      (componentVariants?.heroHomepage?.carousel?.navigation as
                        | 'white'
                        | 'brand') ?? fallbackComponentVariantColor,
                    paginationVariant:
                      (componentVariants?.heroHomepage?.carousel?.pagination as ColorTypes) ??
                      fallbackComponentVariantColor,
                  }}
                >
                  {heroSlideList?.map((heroSlide: Hero, index) => {
                    return (
                      <React.Fragment key={index}>
                        {heroSlide?.fields?.eyebrowText?.value && (
                          <Label
                            className={label()}
                            text={heroSlide?.fields?.eyebrowText}
                            color={
                              (componentVariants?.heroHomepage?.label as ColorTypes) ??
                              fallbackComponentVariantColor
                            }
                            backgroundColor={labelBg()}
                          />
                        )}
                        <div className={headlineTextWrapper()}>
                          <Text
                            className={headlineText()}
                            encode={false}
                            field={heroSlide?.fields?.headline}
                            tag="h1"
                          />
                        </div>
                        {heroSlide?.fields?.subTitle?.value !== '' && (
                          <div className={subTitleWrapper()}>
                            <RichTextA11yWrapper field={heroSlide?.fields?.subTitle} />
                          </div>
                        )}
                        <div className={descriptionWrapper()}>
                          <RichTextA11yWrapper field={heroSlide?.fields?.description} />
                        </div>
                        {heroSlide?.fields?.primaryCTA?.value?.text && (
                          <div className={primaryCtaWrapper()}>
                            <Button
                              //added component variant fallback so if there is no value in sitecore field,
                              //it will take the component variant value.
                              color={
                                heroSlide?.fields?.primaryCTAColor?.value ||
                                componentVariants?.heroHomepage?.primaryCta?.color
                              }
                              type={
                                heroSlide?.fields?.primaryCTAType?.value ||
                                componentVariants?.heroHomepage?.primaryCta?.type
                              }
                              label={heroSlide?.fields?.primaryCTA.value.text}
                              tag="a"
                              target={heroSlide?.fields?.primaryCTA?.value?.target}
                              href={heroSlide.fields.primaryCTA.value.href}
                              gtmEvent={{
                                event: 'cta_click',
                                type: 'primary',
                                'gtm.element.dataset.gtmLinkUrl':
                                  heroSlide.fields.primaryCTA.value.href,
                                'gtm.element.dataset.gtmLinkName':
                                  heroSlide.fields.primaryCTA.value.text,
                                'gtm.element.dataset.gtmDatasourceId': dataSource,
                                'gtm.element.dataset.gtmComponentName': componentName,
                              }}
                            >
                              <LinkWrapper
                                className={primaryCtaLinkWrapper()}
                                field={heroSlide?.fields?.primaryCTA}
                              />
                            </Button>
                            {heroSlide?.fields?.secondaryCTA?.value?.text && (
                              <Button
                                // added component variant fallback so if there is no value in sitecore field,
                                // it will take the component variant value.
                                color={
                                  heroSlide?.fields?.secondaryCTAColor?.value ||
                                  componentVariants?.heroHomepage?.secondaryCta?.color
                                }
                                type={
                                  heroSlide?.fields?.secondaryCTAType?.value ||
                                  componentVariants?.heroHomepage?.secondaryCta?.type
                                }
                                label={heroSlide?.fields?.secondaryCTA.value.text}
                                tag="a"
                                target={heroSlide?.fields?.secondaryCTA?.value?.target}
                                href={heroSlide.fields.secondaryCTA.value.href}
                                gtmEvent={{
                                  event: 'cta_click',
                                  type: 'secondary',
                                  'gtm.element.dataset.gtmLinkUrl':
                                    heroSlide.fields.secondaryCTA.value.href,
                                  'gtm.element.dataset.gtmLinkName':
                                    heroSlide.fields.secondaryCTA.value.text,
                                  'gtm.element.dataset.gtmDatasourceId': dataSource,
                                  'gtm.element.dataset.gtmComponentName': componentName,
                                }}
                              >
                                <LinkWrapper
                                  className={secondaryCtaLinkWrapper()}
                                  field={heroSlide?.fields?.secondaryCTA}
                                />
                              </Button>
                            )}
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </Carousel>
              </div>
              {/* SVG - Desktop */}
              {enablePattern?.value && (
                <SVG
                  className={heroSlideSvgLarge()}
                  svg={`Hero/Breakpoint=Large,Brand=${themeName}`}
                />
              )}
              {/* Images */}
              <div className={homeHeroRhsWrapper()}>
                <Carousel
                  hasTrack={false}
                  ref={imageRef}
                  options={{
                    arrows: false,
                    autoplay: false,
                    pagination: false,
                    type: 'fade',
                    drag: false,
                    rewind: true,
                    breakpoints: {
                      996: {
                        padding: {
                          left: 0,
                          right: 0,
                        },
                      },
                    },
                  }}
                  carouselClasses={imageCarousel()}
                  trackClasses={imageCarouselTrack()}
                >
                  {heroSlideList.map((heroSlide: Hero, index) => {
                    return (
                      <div className={heroSlideWrapper()} key={index}>
                        <ImageWrapper
                          field={heroSlide?.fields?.desktopImage}
                          layout="fill"
                          priority={true}
                          className={heroImage()}
                        />
                        {/* SVG - Mobile */}
                        {enablePattern?.value && (
                          <SVG
                            className={heroSlideSvgSmall()}
                            svg={`Hero/Breakpoint=Small,Brand=${themeName}`}
                          />
                        )}
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </div>
        </Container>
      );
    }
  }

  return <HomepageHeroDefaultComponent {...props} />;
};

export default HomepageHero;
