// Global
import React, { useEffect, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { useRouter } from 'next/router';
import { useI18n } from 'next-localization';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartComponents } from 'lib/templates/Feature.Dart.model';
import { Common } from 'lib/templates/Foundation.Dart.model';
import { useRealPathName } from 'lib/utils/use-real-pathname';
import Label from 'helpers/Label/Label';
import QuizResultWidget from 'components/QuizResult';
import Container from '../Layout/DartContainer/DartContainer';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import SVG from 'helpers/SVG/SVG';
import { tv } from 'tailwind-variants';

export type QuizProps = ComponentProps & DartComponents.Quiz.Quiz;
type Questionnaire = DartComponents.Quiz.Questionnaire;
type Option = DartComponents.Quiz.Option;
export type Label = Common.Label.Label;

type SelectedAnswersType = (string | undefined)[];

export const Default = (props: QuizProps): JSX.Element => {
  const { description, title, searchResultDescription, noSearchResultDescription, resultPerPage } =
    props?.fields || {};

  const [count, setCount] = useState(0);
  const router = useRouter();
  const path = useRealPathName();

  const i18n = useI18n();
  const Back = i18n.t('Back');
  const Skip = i18n.t('Skip');
  const Of = i18n.t('of');

  const questions: Questionnaire[] = props?.fields?.questions ?? [];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState<SelectedAnswersType>([]);
  const [showResults, setShowResults] = useState(false);

  const QuizResultsData = {
    resultsPerPage: resultPerPage,
    listingPageName: 'education',
    enableSearch: false,
    filterLabels: [''],
    noSearchResult: noSearchResultDescription,
    searchResultDescriptionText: searchResultDescription,
    title: title,
    selectedAnswers: selectedAnswers,
    setCurrentIndex: setCurrentIndex,
    setSelectedAnswers: setSelectedAnswers,
    setShowResults: setShowResults,
  };

  useEffect(() => {
    setCount((c) => c + 1);
  }, [router]);

  // Handle option selection
  const handleOptionClick = (option: string) => {
    setSelectedAnswers((prevSelectedAnswers) => {
      const updatedAnswers = [...prevSelectedAnswers];
      updatedAnswers[currentIndex] = option;
      return updatedAnswers;
    });

    if (currentIndex + 1 < questions.length) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setShowResults(true);
    }
  };

  // Handle skip button
  const handleSkip = () => {
    setSelectedAnswers((prevSelectedAnswers) => {
      const updatedAnswers = [...prevSelectedAnswers];
      updatedAnswers[currentIndex] = undefined;
      return updatedAnswers;
    });

    if (currentIndex + 1 < questions.length) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setShowResults(true);
    }
  };

  // Handle back button
  const handleBack = () => {
    if (currentIndex > 0) {
      setSelectedAnswers((prevSelectedAnswers) => {
        const updatedAnswers = [...prevSelectedAnswers];
        updatedAnswers[currentIndex] = undefined;
        return updatedAnswers;
      });
      setCurrentIndex(currentIndex - 1);
    }
  };

  // check if it is the first question
  const isFirstQuestion: boolean = currentIndex === 0 ? true : false;

  const tailwindVariants = tv({
    slots: {
      base: ['bg-components-icon-card-listing-color-brand-bg', 'grid', 'relative'],
      absoluteOverlay: [
        'col-start-1',
        'row-start-1',
        'self-center',
        'absolute',
        'w-full',
        'h-full',
        'overflow-hidden',
      ],
      innerContainer: ['col-start-1', 'row-start-1'],

      flexCenter: ['flex', 'flex-col', 'gap-0', 'items-center', 'justify-center', 'relative'],
      cardContainer: [
        'bg-components-icon-card-listing-color-inner-bg',
        'md:p-10',
        'py-components-icon-card-listing-spacing-small-padding-inner-y',
        'px-components-icon-card-listing-spacing-small-padding-inner-x',
        'flex',
        'flex-col',
        'gap-0',
        'items-start',
        'justify-start',
        'shrink-0',
        'md:w-[450px]',
        'w-full',
        'relative',
        'md:my-components-promo-spacing-large-padding-y',
        'md:mx-components-promo-spacing-large-padding-x',
      ],
      titleText: [
        'text-components-promo-color-default-title',
        'text-left',
        'font-header-large-xSmall',
        'text-header-large-xSmall',
        'leading-header-large-xSmall',
        'relative',
        'self-stretch',
      ],
      descriptionText: [
        'text-components-promo-color-default-body',
        'text-left',
        'font-bodySans-medium',
        'text-bodySans-medium',
        'leading-bodySans-medium',
        'relative',
        'self-stretch',
      ],
      paginationText: [
        'text-components-pagination-on-white-accent-scroll',
        'text-left',
        'font-header-small-xSmall',
        'text-header-small-xSmall',
        'leading-header-small-xSmall',
        'relative',
        'self-stretch',
        'transition-all',
        'ease-in-out',
      ],
      progressBarWrapper: [
        'rounded-components-pagination-scrollbar-radius',
        'flex',
        'gap-2',
        'items-start',
        'justify-end',
        'self-stretch',
        'shrink-0',
        'relative',
      ],
      progressBar: [
        'bg-components-pagination-on-white-bg',
        'rounded-components-pagination-scrollbar-radius',
        'flex-1',
        'h-1',
        'relative',
        'overflow-hidden',
      ],
      progressFill: [
        'bg-components-pagination-on-white-accent-scroll',
        'transition-all',
        'duration-500',
        'rounded-components-pagination-scrollbar-radius',
        'h-1',
        'absolute',
        'left-0',
        'top-0',
      ],
      questionText: [
        'text-themes-general-surface-color-light-title',
        'text-center',
        'md:font-header-large-small',
        'md:text-header-large-small',
        'md:leading-header-large-small',
        'font-header-small-small',
        'text-header-small-small',
        'leading-header-small-small',
        'relative',
      ],
      buttonGroup: [
        'flex',
        'gap-4',
        'items-start',
        'justify-center',
        'flex-wrap',
        'w-full',
        '[&>*]:flex-1',
      ],
      quizWrapper: [
        'pb-10 flex flex-col gap-6 items-center justify-center self-stretch shrink-0 relative',
      ],
      progressBarContainer: [
        'flex flex-col gap-spacing-spacing-3 items-start justify-center flex-1 relative w-full',
      ],
      quizContainer: [
        'pt-10 pb-10 flex flex-col gap-6 items-center justify-start self-stretch shrink-0 relative',
      ],
      quizSteps: ['flex flex-row-reverse justify-between self-stretch '],
      quizLogo: [
        'shrink-0 w-components-icon-card-dimensions-large-big-icon-width h-components-icon-card-dimensions-large-big-icon-height relative overflow-hidden',
      ],
      isFirstQuestionStyle: [''],
    },

    variants: {
      progressState: {
        start: { progressFill: ['right-[100%]'] },
        mid: { progressFill: ['right-[22%]'] },
        end: { progressFill: ['right-[0%]'] },
      },
      isFirstQuestion: {
        true: {
          isFirstQuestionStyle: ['hidden'],
        },
      },
    },
  });
  const {
    base,
    absoluteOverlay,
    innerContainer,
    flexCenter,
    cardContainer,
    titleText,
    descriptionText,
    progressBarWrapper,
    progressBarContainer,
    paginationText,
    progressBar,
    questionText,
    buttonGroup,
    quizWrapper,
    quizContainer,
    quizSteps,
    quizLogo,
    isFirstQuestionStyle,
  } = tailwindVariants({ isFirstQuestion: isFirstQuestion });
  return (
    <div className={base()}>
      {!showResults ? (
        <>
          <div className={absoluteOverlay()}>
            <SVG svg={`Pull-Quote/decor,Brand=Raid`} />
          </div>
          <Container className={innerContainer()} fullWidth>
            <div className={flexCenter()}>
              <>
                <div className={cardContainer()}>
                  <div className={quizWrapper()}>
                    {/* Quiz card title */}
                    {title?.value && (
                      <div className={titleText()}>
                        <Text encode={false} field={title} tag="h2" />
                      </div>
                    )}
                    {/* Quiz card description */}
                    {description?.value && (
                      <div className={descriptionText()}>
                        <RichTextA11yWrapper field={description} />
                      </div>
                    )}
                  </div>
                  <div className={progressBarContainer()}>
                    <div className={paginationText()}>
                      {currentIndex + 1} {Of} {questions.length}
                    </div>
                    <div className={progressBarWrapper()}>
                      {questions.map((_data, key) => {
                        // Determine progress state
                        const { progressFill } = tailwindVariants({
                          progressState:
                            currentIndex + 1 == questions.length
                              ? 'end'
                              : key == currentIndex
                              ? 'mid'
                              : key <= currentIndex
                              ? 'end'
                              : 'start',
                        });

                        return (
                          <div key={key} className={progressBar()}>
                            <div className={progressFill()} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className={quizContainer()}>
                    {questions[currentIndex]?.fields?.icon?.value?.src && (
                      <div className={quizLogo()}>
                        <ImageWrapper field={questions[currentIndex]?.fields?.icon} />
                      </div>
                    )}

                    {/* Quiz Question */}
                    {questions[currentIndex]?.fields?.question?.value && (
                      <div className={questionText()}>
                        <Text field={questions[currentIndex]?.fields?.question} />
                      </div>
                    )}
                    <div className={buttonGroup()}>
                      {questions[currentIndex]?.fields?.options?.map((option: Option, key) => {
                        const label: Label = option?.fields?.optionValue ?? {};
                        return (
                          <Button
                            key={key}
                            title={option?.fields?.optionText?.value}
                            label={option?.fields?.optionText?.value}
                            type="outline"
                            onClick={() => handleOptionClick(label?.fields?.title?.value ?? '')}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <div className={quizSteps()}>
                    <Button
                      label={Skip}
                      title={Skip}
                      iconRight="chevron_right"
                      type="text"
                      onClick={handleSkip}
                    />
                    <Button
                      childClass={isFirstQuestionStyle()}
                      label={Back}
                      title={Back}
                      iconLeft="chevron_left"
                      type="text"
                      onClick={handleBack}
                    />
                  </div>
                </div>
              </>
            </div>
          </Container>
        </>
      ) : (
        <>
          {(() => {
            QuizResultsData.filterLabels = selectedAnswers
              ?.map((c) => c)
              .filter((c): c is string => c !== undefined);
            QuizResultsData.enableSearch = true;
            return <QuizResultWidget {...QuizResultsData} key={path + count} rfkId="rfkid_13" />;
          })()}
        </>
      )}
    </div>
  );
};
